// store.js
import { configureStore } from '@reduxjs/toolkit';
import enterpriseFeatures from '../features/enterprise/enterprise';

const rootReducer = {
    enterprise: enterpriseFeatures,
};

const store = configureStore({
    reducer: rootReducer,
});

export default store;
