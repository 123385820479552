import { useContext } from 'react';

import { UserContext } from '../context/user-context';

// ----------------------------------------------------------------------

export function useUserContext() {
    const context = useContext(UserContext);

    if (!context) {
        throw new Error('useAuthContext: Context must be used inside AuthProvider');
    }

    return context;
}
