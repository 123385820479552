import { createSlice } from '@reduxjs/toolkit'

export const enterpriseFeatures = createSlice({
    name: 'enterprise',
    initialState: {},
    reducers: {
        addEnterprise: (state, action) => {
            state = action.payload
            return state
        },
    },
})

export const { addEnterprise } = enterpriseFeatures.actions

export default enterpriseFeatures.reducer