import { BusinessDiagnosticService } from 'pulse-client-module/client-web';
import { useMemo, useCallback } from 'react';
import { useSetState } from 'src/hooks/use-set-state';
import { UserContext } from '../user-context';

// ----------------------------------------------------------------------

export function UserProvider({ children }) {
    const { state, setState } = useSetState({
        user: {},
        business_diagnostic: [],
    });

    const updateUser = useCallback((newDataUser) => {
        setState({ user: newDataUser });
    }, [setState]);

    const updateBusinessDiagnostic = useCallback((newDataBusinessDiagnostic) =>
        BusinessDiagnosticService.returnAllBusinessDiagnosticByCompanyDataId(newDataBusinessDiagnostic.company_data_id)
            .then((response) => {
                setState({ business_diagnostic: response.data });
                return response.data;  // Return the response data
            })
            .catch((error) => {
                console.error('Erro ao atualizar o diagnóstico de negócios:', error);
            })
        , [setState]);


    const memoizedValue = useMemo(
        () => ({
            user: state.user,
            business_diagnostic: state.business_diagnostic,
            updateUser,
            updateBusinessDiagnostic,
        }),
        [state, updateUser, updateBusinessDiagnostic]
    );

    return <UserContext.Provider value={memoizedValue}>{children}</UserContext.Provider>;
}
